.react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100%;
    border-radius: 5px;
    border-color: rgba(212, 212, 216, var(--tw-border-opacity));;
    border-width: 2px;
    font-size: 14px;
}

.react-datepicker-wrapper .react-datepicker__input-container input:focus {
    outline: 0px;
    border-color: #00B5B1;
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-color: #00B5B1;
}
